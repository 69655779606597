<template>
  <div id="container" class="container">
    <div class="top-banner">
      <!-- logo + menu -->
      <div class="top-bar">
        <div class="logo">
          <div class="icon"></div>
          <div class="text">
            <div class="main">SSI</div>
            <div class="sub">SoSoValue Index</div>
          </div>
        </div>
        <div class="menu">
          <div class="menu-item" @click="changeMenu('HOME_INDEX')">
            <div class="text">Home</div>
          </div>
          <div class="menu-item active" @click="changeMenu('ASSETS')">
            <div class="text">Assets</div>
          </div>
        </div>
      </div>
      <!-- slogan -->
      <div class="content">
        <div class="slogan">
          <div class="main">Invest in Premium Crypto Combinations.</div>
          <div class="sub">Build Your Crypto Bundle.</div>
        </div>
      </div>
    </div>

    <div class="list-title">Assets</div>
    <div class="list-block">
      <div class="list">
        <div class="ls-head">
          <div class="ls-row">
            <div class="ls-col">Asset</div>
            <div class="ls-col col-right">Price</div>
            <div class="ls-col col-right">24H Change</div>
            <div class="ls-col col-right">24H VOL</div>
            <div class="ls-col col-right">MarketCap</div>
            <div class="ls-col col-right">1M ROI</div>
            <div class="ls-col col-right">YTD</div>
            <div class="ls-col col-center">Status</div>
          </div>
        </div>
        <div class="ls-body">
          <div class="ls-row" v-for="item in tableData" :key="item.product" @click="onClickRow(item)">
            <div class="ls-col">{{ item.product }}</div>
            <div class="ls-col col-right">${{ proxy.abs.Util.floor(item.price, 2) }}</div>
            <div class="ls-col col-right" :class="{positive:item.change24H>0, negative:item.change24H<0}">{{ proxy.abs.Util.addSign(item.change24H) }}%</div>
            <div class="ls-col col-right">${{ proxy.abs.Util.formatNumber(item.vol24H, 2) }}</div>
            <div class="ls-col col-right">${{ proxy.abs.Util.formatNumber(item.marketCap, 2) }}</div>
            <div class="ls-col col-right" :class="{positive:item.roi1Month>0,negative:item.roi1Month<0}">{{ proxy.abs.Util.addSign(item.roi1Month) }}%</div>
            <div class="ls-col col-right" :class="{positive:item.ytd>0,negative:item.ytd<0}">{{ proxy.abs.Util.addSign(item.ytd) }}%</div>
            <div class="ls-col col-center" :class="{comming:item.tradable===false,online:item.tradable===true}">{{ item.tradable == true ? "Online" : "Coming soon" }}</div>
          </div>
        </div>
      </div>
    </div>

    <bottom-bar style="margin-top: 90px"></bottom-bar>
  </div>
</template>

<script setup>
import { getCurrentInstance, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
const { proxy } = getCurrentInstance();
const route = useRoute();
const router = useRouter();
const changeMenu = (menuItem) => {
  if (route.path === proxy.abs.routes[menuItem]) {
    return;
  }
  router.push(proxy.abs.routes[menuItem]);
};

const tableData = ref();

const onClickRow = (row) => {
  router.push({
    name: "AssetDetail",
    params: {
      assetId: row.id,
    },
  });
};
const getProductList = () => {
  proxy.api.getProductList(
    proxy,
    {},
    (data) => {
      for (var i = 0; i < data.data.products.length; i++) {
        data.data.products[i].product = data.data.products[i].product.replace('_', '')
      }
      tableData.value = data.data.products;
    },
    (message) => {
      console.log(message);
    }
  );
};
onMounted(() => {
  getProductList();
});
</script>

<style scoped>
.top-banner {
  width: 100%;
  height: 460px;
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(./assets/img/home-top-banner.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.top-banner .top-bar {
  width: 100%;
  height: 60px;
  padding: 0 130px;
  display: flex;
}
.top-banner .top-bar .logo {
  margin-right: 60px;
  display: flex;
}
.top-banner .top-bar .logo .icon {
  width: 60px;
  height: 60px;
  background: url(./assets/icon/soso.svg) center no-repeat;
  background-size: contain;
}
.top-banner .top-bar .logo .text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.top-banner .top-bar .logo .text .main {
  font-size: 18px;
  font-weight: 700;
  color: #313131;
}
.top-banner .top-bar .logo .text .sub {
  font-size: 12px;
  color: #525252;
}
.top-banner .top-bar .menu {
  display: flex;
  align-items: center;
}
.top-banner .top-bar .menu .menu-item {
  margin: 0 20px;
  padding: 0 4px;
  border-bottom: 2px solid transparent;
  cursor: pointer;
}
.top-banner .top-bar .menu .menu-item.active {
  border-color: #ff4e1f;
}
.top-banner .top-bar .menu .menu-item .text {
  font-size: 18px;
}
.top-banner .top-bar .menu .menu-item.active .text {
  color: #ff4e1f;
}

.top-banner .content {
  flex: 1;
  padding: 80px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.top-banner .slogan {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.top-banner .slogan .main {
  color: #0a0a0a;
  font-family: "Space Grotesk";
  font-size: 62px;
  font-weight: 700;
  margin-bottom: 20px;
}
.top-banner .slogan .sub {
  color: #525252;
  font-family: Lato;
  font-size: 22px;
  font-weight: 400;
  line-height: 140%;
}

.top-banner .key-points {
  display: flex;
}
.top-banner .key-points .key-item {
  padding: 0 80px;
}
.top-banner .key-points .key-item .label {
  color: #161c2d;
  font-family: Lato;
  font-size: 20px;
  font-weight: 400;
  line-height: 140%;
}
.top-banner .key-points .key-item .value {
  color: #ff4f20;
  font-family: "Space Grotesk";
  font-size: 36px;
  font-weight: 700;
}

.list-title {
  padding: 40px 0 20px 0;
  color: #0a0a0a;
  font-family: "Space Grotesk";
  font-size: 36px;
  font-weight: 700;
  text-align: center;
}
.list-block {
  padding: 0 120px;
}
.list {
  min-width: 1200px;
  border-top: 1px solid #e5e5e5;
  border-left: 1px solid #e5e5e5;
}
.list .ls-row {
  display: flex;
}
.list .ls-col {
  width: 12.5%;
  padding: 0 18px;
  color: #0a0a0a;
  border-right: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  align-items: center;
}
.list .ls-head .ls-col {
  height: 48px;
  background-color: #f5f8fa;
  font-family: "Space Grotesk";
  font-size: 18px;
  font-weight: 500;
  color: #0a0a0a;
}
.list .ls-body .ls-row {
  cursor: pointer;
}
.list .ls-body .ls-row:hover {
  background-color: #dfebfb;
}
.list .ls-body .ls-col {
  height: 40px;
}

.list .ls-col.positive {
  color: #05a83e;
}
.list .ls-col.negative {
  color: #ff4f20;
}
.list .ls-col.comming {
  color: #b1b1b1;
}
.list .ls-col.online {
  color: #0370ff;
}
.col-center {
  justify-content: center;
}
.col-right {
  justify-content: flex-end;
}
</style>
