export default {
    common: {
        confirm: '确定',
        cancel: '取消',
        ok: '好的',
        yes: '是',
        no: '否',
    },
    home: {
        index: {
            //
        }
    },
    asset: {
        list: {
            //
        },
        info: {
            //
        }
    },
}