<template>
  <div class="top-bar">
    <div class="logo">
      <div class="icon"></div>
      <div class="text">
        <div class="main" v-text="$t('topbar.logo')"></div>
        <div class="sub" v-text="$t('topbar.subLogo')"></div>
      </div>
    </div>
    <div class="menu">
      <div class="menu-item">
        <button
          class="button link-button"
          v-text="$t('topbar.home')"
          @click="clickMenuItem(MENU_TIEM_HOME)"
        ></button>
      </div>
      <div class="menu-item">
        <button
          class="button link-button"
          v-text="$t('topbar.assets')"
          @click="clickMenuItem(MENU_ITEM_ASSETS)"
        ></button>
      </div>
    </div>
    <!-- <div class="account">
      <div class="connect-wallet" v-if="!walletAddress">
        <button
          class="button primary-button"
          v-text="$t('topbar.connectWallet')"
          @click="clickConnectWallet"
        ></button>
      </div>
      <div
        class="connected-wallet"
        v-if="walletAddress"
        @click="clickConnectedWallet"
      >
        <div class="static" v-text="'0x45...6789'"></div>
        <i class="icon arrow-down"></i>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "TopBar",
  props: {
    menu: {
      type: String,
      default: () => {
        return "HOME";
      },
    },
  },
  data() {
    return {
      MENU_TIEM_HOME: "HOME_INDEX",
      MENU_ITEM_ASSETS: "ASSETS",
      walletAddress: "",
    };
  },
  mounted() {
    this.walletAddress = this.abs.Store.get("walletAddress");
  },
  methods: {
    clickMenuItem: function (menuItem) {
      this.$emit("changeMenu", menuItem);
    },
    checkConnect: async function () {
      if (window.ethereum) {
        try {
          // 请求账户信息
          const accounts = await window.ethereum.request({
            method: "eth_accounts",
          });

          if (accounts.length > 0) {
            console.log("钱包已连接:", accounts[0]);
            return true; // 表示钱包已连接
          } else {
            console.log("钱包未连接");
            return false; // 表示钱包未连接
          }
        } catch (error) {
          console.error("检查钱包连接时出错:", error);
          return false;
        }
      } else {
        console.error("没有找到以太坊钱包");
        return false;
      }
    },
    clickConnectWallet: async function () {
      await this.checkConnect();
      // 连接钱包
      await window.ethereum.request({
        method: "eth_requestAccounts",
      });
    },
    clickConnectedWallet: function () {
      alert(456);
    },
  },
};
</script>

<style scoped>
.top-bar {
  height: 60px;
  padding: 0 20px;
  border-bottom: 1px solid var(--sep-color);
  display: flex;
  align-items: center;
  background-color: var(--background-default);
}
.logo {
  margin-right: 80px;
  display: flex;
  align-items: center;
}
.logo .icon {
  width: 60px;
  height: 60px;
  background: url(../assets/icon/soso.svg) center no-repeat;
  background-size: contain;
}
.logo .text {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.logo .text .main {
  font-size: 18px;
  font-weight: 700;
  color: var(--text-default);
}
.logo .text .sub {
  font-size: 12px;
  font-weight: 500;
  color: var(--text-default);
}
.menu {
  flex: 1;
  display: flex;
}
.account {
  display: flex;
  justify-content: center;
  align-items: center;
}
.account .connect-wallet .button {
  padding: 0 30px;
  border-radius: 20px;
}
.account .connected-wallet {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.account .connected-wallet .static {
  font-size: 16px;
  color: var(--text-default);
}
.account .connected-wallet .icon {
  background-color: var(--text-default);
}
</style>