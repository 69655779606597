<template>
  <div class="bottom-bar">
    <div class="block left">
      <div class="head">
        <div class="logo"></div>
        <div class="name">SoSoValue Labs</div>
      </div>
      <div class="copyright">
        Copyright @ 2021 - 2024 SoSoValue Labs. All rights reserved.
      </div>
    </div>
    <div class="block right">
      <div class="item email">
        <div class="label">Contact:</div>
        <div class="value">
          <a href="mailto:protocol@sosovalue.com">protocol@sosovalue.com</a>
        </div>
      </div>
      <div class="item icon twitter">
        <a class="link" href="https://twitter.com/SoSoValueCrypto"></a>
      </div>
      <div class="item icon telegram">
        <a class="link" href="https://t.me/SoSoValueCommunity"></a>
      </div>
    </div>
  </div>
</template>

<script></script>

<style scoped>
.bottom-bar {
  width: 100%;
  height: 120px;
  background-color: #f1f2f4;
  display: flex;
}
.bottom-bar .block {
  width: 50%;
}
.bottom-bar .block.left {
  padding: 0 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.bottom-bar .block.left .head {
  display: flex;
  align-items: center;
}
.bottom-bar .block.left .head .logo {
  width: 34px;
  height: 34px;
  background: url(../assets/icon/soso.svg) center no-repeat;
  background-size: contain;
}
.bottom-bar .block.left .head .name {
  font-size: 16px;
}
.bottom-bar .block.left .copyright {
  font-size: 12px;
  color: var(--text-secondary);
}
.bottom-bar .block.right {
  padding: 0 80px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.bottom-bar .block.right .email {
  margin-right: 20px;
}
.bottom-bar .block.right .email {
  display: flex;
}
.bottom-bar .block.right .email .label {
  color: var(--text-secondary);
  margin-right: 10px;
}
.bottom-bar .block.right .item.icon {
  width: 30px;
  height: 30px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px;
}
.bottom-bar .block.right .item.icon.twitter {
  background-image: url(../assets/icon/twitter.svg);
}
.bottom-bar .block.right .item.icon.telegram {
  background-image: url(../assets/icon/telegram.svg);
}
.bottom-bar .block.right .item.icon .link {
  display: block;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
</style>