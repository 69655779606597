<template>
  <div class="docs-list">
    <h1>Docs</h1>
    <ul>
      <li v-for="(doc, index) in docs" :key="index">
        <a :href="doc.url" target="_blank"
          ><div class="title">{{ doc.title }}</div></a
        >
        <span class="date">{{ proxy.abs.Util.formatTimestamp(doc.date) }}</span>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { getCurrentInstance, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
const { proxy } = getCurrentInstance();
const route = useRoute();
// const router = useRouter();
const docs = ref();

const getAssetDocs = () => {
  proxy.api.getAssetDocs(
    proxy,
    {
      indexId: route.params.assetId,
    },
    (data) => {
      docs.value = data.data.docs;
    },
    (message) => {
      console.log(message);
    }
  );
};

onMounted(() => {
  getAssetDocs();
});
</script>

<style scoped>
.docs-list {
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.docs-list h1 {
  margin-bottom: 20px;
  font-size: 25px;
  align-self: center;
}

.docs-list ul {
  list-style: none;
  padding: 0;
}

.docs-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px 0px 10px;
  border-bottom: 1px solid #eee;
}

.docs-list .date {
  font-size: 14px;
  color: #888;
}

.title {
  font-size: 18px;
}
</style>