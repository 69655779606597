import abs from '@/assets/js/abs';

let API_URL = 'https://ssi.sosovalue.com/api'
let invoke = function (uri, _this, body, success, failure) {
    body = body || {}
    body.uticket = abs.Store.uticket() || ''
    fetch(API_URL + uri, {
        method: 'POST',
        body: JSON.stringify(body)
    }).then(response => response.json()).then(data => {
        if (data.code !== '0') {
            failure && failure.call(_this, data.Message, data.Code);
        }
        else {
            success && success.call(_this, data);
        }
    }).catch(error => {
        failure && failure.call(_this, error);
    })
};

export default {
    login: function (_this, data, success, failure) {
        invoke('/login', _this, data, success, failure);
    },
    getProductList: function (_this, data, success, failure) {
        invoke('/product_list', _this, data, success, failure);
    },
    getAssetDetail: function (_this, data, success, failure) {
        invoke('/asset_detail', _this, data, success, failure);
    },
    loadKlineData: function (_this, data, success, failure) {
        invoke('/kline', _this, data, success, failure);
    },
    getHomePageData: function (_this, data, success, failure) {
        invoke('/home', _this, data, success, failure);
    },
    getAssetDocs: function (_this, data, success, failure) {
        invoke('/asset_docs', _this, data, success, failure);
    },
}