let _toastTimer = null;

export default {
    routes: {
        HOME_INDEX: '/',
        ASSETS: '/assets',
        ASSET_DETAIL: '/asset_detail/:assetId',
        ASSET_DOCS: '/asset_docs/:assetId',
    },
    common: {
        EBoolean: {
            YES: 1,
            NO: 0
        },
        ELanguage: {
            EN_US: {
                code: 'en-US'
            },
            ZH_CN: {
                code: 'zh-CN'
            }
        }
    },
    Toast: new function () {
        this.show = function (content, timeout) {
            let toast = document.querySelector('.msg-toast');
            toast.querySelector('.content .text').innerText = content;
            let classList = toast.className.split(' ');
            classList.push('show');
            toast.className = classList.join(' ');

            if (_toastTimer) {
                clearTimeout(_toastTimer);
                _toastTimer = null;
            }
            _toastTimer = setTimeout(() => {
                let classList = toast.className.split(' ');
                for (let i = 0; i < classList.length; i++) {
                    if (classList[i].trim() === 'show') {
                        classList.splice(i, 1);
                        break;
                    }
                }
                toast.className = classList.join(' ');
                _toastTimer = null;
            }, (timeout || 3000));
        };
        this.alert = function (content) {
            let toast = document.querySelector('.msg-toast');
            toast.querySelector('.content .text').innerText = content;
            let classList = toast.className.split(' ');
            classList.push('show');
            toast.className = classList.join(' ');

            if (_toastTimer) {
                clearTimeout(_toastTimer);
                _toastTimer = null;
            }
        };
    },
    Store: {
        set(key, value) {
            localStorage.setItem(key, value);
        },
        get(key) {
            return localStorage.getItem(key);
        },
        client(client) {
            if (client === undefined) {
                return this.get('client');
            }
            else {
                this.set('client', client);
            }
        },
        locale(locale) {
            if (locale === undefined) {
                return this.get('locale') || 'en-US';
            }
            else {
                this.set('locale', locale);
            }
        },
        uticket(uticket) {
            if (uticket === undefined) {
                return localStorage.getItem('uticket') || '';
            }
            else {
                localStorage.setItem('uticket', uticket);
            }
        }
    },
    Cookie: {
        set(key, value, seconds) {
            if (seconds) {
                let expires = new Date();
                expires.setTime(expires.getTime() + seconds * 1000);
                document.cookie = key + '=' + escape(value) + ';expires=' + expires.toGMTString() + ';path=/';
            }
            else {
                document.cookie = key + '=' + escape(value) + ';path=/';
            }
        },
        get(key) {
            key = key + '=';
            let ks = document.cookie.indexOf(key);
            while (ks >= 0) {
                let vs = ks + key.length;
                let ve = document.cookie.indexOf(';', vs);
                if (ve < 0) {
                    ve = document.cookie.length;
                }
                let value = unescape(document.cookie.substring(vs, ve));
                if (value) {
                    return value;
                }
                ks = document.cookie.indexOf(key, ve);
            }
            return null;
        }
    },
    Url: {
        encode(text) {
            return text ? encodeURIComponent(text) : '';
        },
        decode(text) {
            return text ? decodeURIComponent(text) : '';
        }
    },
    Dom: new function () {
        this.addClass = function (el, clazz) {
            if (!clazz) {
                return;
            }
            let classList = el.className.split(' ');
            classList.push(clazz);
            el.className = classList.join(' ');
        };
        this.removeClass = function (el, clazz) {
            if (!clazz) {
                return;
            }
            let classList = el.className.split(' ');
            for (let i = 0; i < classList.length; i++) {
                if (classList[i].trim() === clazz) {
                    classList.splice(i, 1);
                    break;
                }
            }
            el.className = classList.join(' ');
        };
        this.replaceClass = function (el, oldClazz, newClazz) {
            this.removeClass(el, oldClazz);
            this.addClass(el, newClazz);
        };
    },
    Util: {
        formatNumber(number, bits) {
            number = Number(number) || 0;
            let unit = '';
            if (number > 10000000000) {
                number = Math.floor(number / 10) / 100;
                unit = 'K';
                if (number > 10000000000) {
                    number = Math.floor(number / 10) / 100;
                    unit = 'M';
                    if (number > 10000000000) {
                        number = Math.floor(number / 100) / 100;
                        unit = 'B';
                    }
                }
            }
            if (bits === 0 || bits > 0) {
                number = number * Math.pow(10, bits);
                number = Math.round(number);
                number = number / Math.pow(10, bits);
                number = number.toFixed(bits);
            }
            else {
                let match = number.toExponential().match(/\d(?:\.(\d*))?e([+-]\d+)/);
                number = number.toFixed(Math.max(0, (match[1] || '').length - match[2]));
            }
            let parts = number.split('.');
            return parts[0].replace(/\d(?=(?:\d{3})+\b)/g, '$&,') + (parts.length > 1 ? '.' + parts[1] : '') + unit;
        },
        round(number, bits) {
            number = Number(number) || 0;
            let power = Math.pow(10, bits);
            return Math.round(number * power) / power;
        },
        floor(number, bits) {
            number = Number(number) || 0;
            let power = Math.pow(10, bits);
            return Math.floor(number * power) / power;
        },
        ceil(number, bits) {
            number = Number(number) || 0;
            let power = Math.pow(10, bits);
            return Math.ceil(number * power) / power;
        },
        addSign(num) {
            const x = num
            num = this.formatNumber(num, 2)
            if (x > 0) {
                return "+" + num;
            } else if (num < 0) {
                return "" + num; // 这里不需要添加 "+"，因为负数本身就带负号
            } else {
                return "0"; // 处理 0 的情况
            }
        },
        formatTimestamp(timestamp) {
            const date = new Date(timestamp * 1000);
            const year = date.getFullYear();
            const month = ("0" + (date.getMonth() + 1)).slice(-2);
            const day = ("0" + date.getDate()).slice(-2);
            return `${year}-${month}-${day}`;
        }
    },
    Hexer: {
        encode(text) {
            let utf8 = [];
            for (let i = 0; i < text.length; i++) {
                let charcode = text.charCodeAt(i);
                if (charcode < 0x80) {
                    utf8.push(charcode);
                }
                else if (charcode < 0x800) {
                    utf8.push(0xc0 | (charcode >> 6), 0x80 | (charcode & 0x3f));
                }
                else if (charcode < 0xd800 || charcode >= 0xe000) {
                    utf8.push(0xe0 | (charcode >> 12), 0x80 | ((charcode >> 6) & 0x3f), 0x80 | (charcode & 0x3f));
                }
                else {
                    i++;
                    charcode = 0x10000 + (((charcode & 0x3ff) << 10) | (text.charCodeAt(i) & 0x3ff));
                    utf8.push(0xf0 | (charcode >> 18), 0x80 | ((charcode >> 12) & 0x3f), 0x80 | ((charcode >> 6) & 0x3f), 0x80 | (charcode & 0x3f));
                }
            }
            let hex = utf8.map(n => n.toString(16));
            return '0x' + hex.join('');
        }
    }
}