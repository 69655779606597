import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import { createI18n } from 'vue-i18n'

import en_us from '@/assets/res/en_us'
import zh_cn from '@/assets/res/zh_cn'

import '@/assets/css/abs.css'
import abs from '@/assets/js/abs'
import api from '@/assets/js/api'

import App from './App.vue'
import HomeIndex from '@/HomeIndex'
import AseetInfos from '@/AssetInfos'
import AssetDetail from '@/AssetDetail.vue'
import AssetDocs from '@/AssetDocs.vue'
import TopBar from "@/components/TopBar";
import BottomBar from "@/components/BottomBar";

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            name: "Home",
            path: abs.routes.HOME_INDEX,
            component: HomeIndex,
        },
        {
            name: "Assets",
            path: abs.routes.ASSETS,
            component: AseetInfos,
        },
        {
            name: 'AssetDetail',
            path: abs.routes.ASSET_DETAIL,
            component: AssetDetail,
            props: true
        },
        {
            name: 'AssetDocs',
            path: abs.routes.ASSET_DOCS,
            component: AssetDocs,
            props: true

        }
    ]
})

const i18n = createI18n({
    locale: localStorage.getItem('locale') || 'en-US',
    datetimeFormats: {
        'en-US': {
            datetime: {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
                hour12: false
            },
            date: {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
            },
            time: {
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
                hour12: false
            },
        },
        'zh-CN': {
            datetime: {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
                hour12: false
            },
            date: {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
            },
            time: {
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
                hour12: false
            },
        }
    },
    messages: {
        'en-US': en_us,
        'zh-CN': zh_cn
    },
})

const app = createApp(App)
app.use(router)
app.use(i18n)
app.use(ElementPlus)
app.config.globalProperties.abs = abs
app.config.globalProperties.api = api
app.component("TopBar", TopBar)
app.component("BottomBar", BottomBar)
app.mount('#app')
