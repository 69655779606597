export default {
    common: {
        confirm: 'Confirm',
        cancel: 'Cancel',
        ok: 'Ok',
        yes: 'Yes',
        no: 'No',
    },
    topbar: {
        logo: 'SSI',
        subLogo: 'SoSoValue Index',
        home: 'Home',
        assets: 'Assets',
        connectWallet: 'Connect Wallet',
    },
    home: {
        index: {
            logo: 'Composite Asset',
            connectWallet: 'Connect Wallet',
        }
    },
    asset: {
        list: {
            //
        },
        info: {
            //
        }
    },
}