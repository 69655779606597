<template>
  <div id="container" class="container">
    <div class="top-banner">
      <!-- logo + menu -->
      <div class="top-bar">
        <div class="logo">
          <div class="icon"></div>
          <div class="text">
            <div class="main">SSI</div>
            <div class="sub">SoSoValue Index</div>
          </div>
        </div>
        <div class="menu">
          <div class="menu-item active" @click="changeMenu(MENU_TIEM_HOME)">
            <div class="text">Home</div>
          </div>
          <div class="menu-item" @click="changeMenu(MENU_ITEM_ASSETS)">
            <div class="text">Assets</div>
          </div>
        </div>
      </div>
      <!-- slogan + keypoints -->
      <div class="content">
        <div class="slogan">
          <div class="main">Build Your Crypto Bundle.</div>
          <div class="sub">Invest in Premium Crypto Combinations.</div>
        </div>

        <!-- <div class="key-points">
          <div class="key-item">
            <div class="label">TVL</div>
            <div class="value">${{ this.abs.Util.formatNumber(this.tvl) }}</div>
          </div>
          <div class="key-item">
            <div class="label">24H Vol</div>
            <div class="value">
              ${{ this.abs.Util.formatNumber(this.vol24H, 2) }}
            </div>
          </div>
        </div> -->
      </div>
    </div>

    <!-- top-earnings -->
    <div class="tops">
      <div class="title">Top Earnings</div>
      <div class="list">
        <div
          class="block"
          v-for="(item, index) in this.topAssets"
          :key="index"
          @click="onClickAsset(item.IndexId)"
        >
          <div class="item">
            <div class="main">
              <div class="head">
                <div class="name">{{ item.Name }}</div>
                <div class="price">
                  ${{ this.abs.Util.floor(item.Price, 2) }}
                </div>
              </div>
              <div class="tail">
                <div class="theme">{{ item.Theme }}</div>
              </div>
            </div>
            <div class="sub">
              <div class="sub-item">
                <div class="label">TVL :</div>
                <div class="value">
                  ${{ this.abs.Util.formatNumber(item.Tvl, 2) }}
                </div>
              </div>
              <div class="sub-item">
                <div class="label">24H Vol :</div>
                <div class="value">
                  ${{ this.abs.Util.formatNumber(item.Vol24H, 2) }}
                </div>
              </div>
              <div class="sub-item">
                <div class="label">YTD :</div>
                <div
                  class="value text-rise"
                  :class="{
                    positive: item.Ytd > 0,
                    negative: item.Ytd < 0,
                  }"
                >
                  {{ this.abs.Util.addSign(item.Ytd) }}%
                </div>
              </div>
            </div>
            <div class="go"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- introduce -->
    <div class="introduce">
      <div class="title">
        <div class="main">Make Your Crypto Investment Smarter</div>
        <div class="sub">Easy to enjoy long-term gains in the crypto industry.</div>
      </div>
      <div class="content">
        <div class="section sec1">
          <div class="icon"></div>
          <div class="label">Transparency and Traceability</div>
          <div class="desc">Our protocol ensures that all transactions and assets are fully transparent and traceable. Every synthetic asset created and traded on our platform is publicly verifiable on blockchain, promoting an environment of trust and openness.</div>
        </div>
        <div class="section sec2">
          <div class="icon"></div>
          <div class="label">Sustainability and Stability</div>
          <div class="desc">Modeled after traditional financial indices, our protocol utilizes a systematic approach to asset compilation and rebalancing. This ensures that each synthetic asset is not only reliable but also designed for long-term sustainability in the ever-evolving crypto market.</div>
        </div>
        <div class="section sec3">
          <div class="icon"></div>
          <div class="label">Additional Yield Opportunities</div>
          <div class="desc">Investors and holders of synthetic assets, as well as those who contribute to their compilation, are eligible for SoSoValue points rewards. These rewards open the door to a plethora of benefits and enhance the overall investment experience by providing additional yield opportunities.</div>
        </div>
      </div>
    </div>

    <div class="partners">
      <div class="title">Partners</div>
      <div class="list">
        <div class="item"><div class="icon hongshan"></div></div>
      </div>
      <div class="list">
        <div class="item"><div class="icon safepal"></div></div>
        <div class="item"><div class="icon trustwallet"></div></div>
        <div class="item"><div class="icon alumniventures"></div></div>
        <div class="item"><div class="icon gsr"></div></div>
        <div class="item"><div class="icon coinsummer"></div></div>
        <div class="item"><div class="icon onepiece"></div></div>
      </div>
    </div>
    <bottom-bar></bottom-bar>
  </div>
</template>
    
<script>
import BottomBar from "./components/BottomBar.vue";
export default {
  name: "HomeIndex",
  components: { BottomBar },
  data() {
    return {
      MENU_TIEM_HOME: "HOME_INDEX",
      MENU_ITEM_ASSETS: "ASSETS",
      code: "",
      tvl: 0,
      vol24H: 0,
      topAssets: [],
    };
  },
  mounted() {
    var _this = this;
    this.load();
    this.timer = setInterval(function () {
      _this.load();
    }, 5000);
  },
  unmounted() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    load: function () {
      this.api.getHomePageData(this, {}, (data) => {
        for (var i = 0; i < data.data.topAssets.length; i++) {
          data.data.topAssets[i].Name = data.data.topAssets[i].Name.replace('_', '')
        }
        this.tvl = data.data.tvl;
        this.vol24H = data.data.vol24H;
        this.topAssets = data.data.topAssets;
      });
    },
    changeMenu: function (menuItem) {
      if (this.$route.path === this.abs.routes[menuItem]) {
        return;
      }
      this.$router.push(this.abs.routes[menuItem]);
    },
    onClickAsset: function (assetId) {
      this.$router.push({
        name: "AssetDetail",
        params: {
          assetId: assetId,
        },
      });
    },
  },
};
</script>

<style scoped>
.top-banner {
  width: 100%;
  height: 460px;
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(./assets/img/home-top-banner.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.top-banner .top-bar {
  width: 100%;
  height: 60px;
  padding: 0 130px;
  display: flex;
}
.top-banner .top-bar .logo {
  margin-right: 60px;
  display: flex;
}
.top-banner .top-bar .logo .icon {
  width: 60px;
  height: 60px;
  background: url(./assets/icon/soso.svg) center no-repeat;
  background-size: contain;
}
.top-banner .top-bar .logo .text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.top-banner .top-bar .logo .text .main {
  font-size: 18px;
  font-weight: 700;
  color: #313131;
}
.top-banner .top-bar .logo .text .sub {
  font-size: 12px;
  color: #525252;
}
.top-banner .top-bar .menu {
  display: flex;
  align-items: center;
}
.top-banner .top-bar .menu .menu-item {
  margin: 0 20px;
  padding: 0 4px;
  border-bottom: 2px solid transparent;
  cursor: pointer;
}
.top-banner .top-bar .menu .menu-item.active {
  border-color: #ff4e1f;
}
.top-banner .top-bar .menu .menu-item .text {
  font-size: 18px;
}
.top-banner .top-bar .menu .menu-item.active .text {
  color: #ff4e1f;
}

.top-banner .content {
  flex: 1;
  /* padding: 60px; */
  padding: 80px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.top-banner .slogan {
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.top-banner .slogan .main {
  color: #0a0a0a;
  font-family: "Space Grotesk";
  font-size: 62px;
  font-weight: 700;
  margin-bottom: 20px;
}
.top-banner .slogan .sub {
  color: #525252;
  font-family: Lato;
  font-size: 22px;
  font-weight: 400;
  line-height: 140%;
}

.top-banner .key-points {
  display: flex;
}
.top-banner .key-points .key-item {
  padding: 0 80px;
}
.top-banner .key-points .key-item .label {
  color: #161c2d;
  font-family: Lato;
  font-size: 20px;
  font-weight: 400;
  line-height: 140%;
}
.top-banner .key-points .key-item .value {
  color: #ff4f20;
  font-family: "Space Grotesk";
  font-size: 36px;
  font-weight: 700;
}

.tops {
  width: 100%;
  background-image: url(./assets/img/bg-tops.png);
}
.tops .title {
  padding-top: 40px;
  color: #0a0a0a;
  font-family: "Space Grotesk";
  font-size: 36px;
  font-weight: 700;
  text-align: center;
}
.tops .list {
  width: 100%;
  height: 170px;
  padding: 0 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tops .list .block {
  width: 33.33%;
  padding: 10px;
}
.tops .list .block .item {
  width: 100%;
  height: 120px;
  padding: 20px;
  border: 1px solid #e7e9ed;
  border-radius: 6px;
  display: flex;
  cursor: pointer;
}
.tops .list .block .item .main {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.tops .list .block .item .main .head {
  line-height: 30px;
  display: flex;
  align-items: center;
}
.tops .list .block .item .main .head .name {
  font-size: 18px;
  font-weight: 700;
  margin-right: 10px;
}
.tops .list .block .item .main .head .price {
  color: var(--primary);
  font-size: 16px;
}
.tops .list .block .item .main .tail {
  display: flex;
}
.tops .list .block .item .main .tail .theme {
  font-family: "Space Grotesk";
  font-size: 14px;
  font-weight: 700;
  padding: 2px 0;
  color: #68d585;
}
.tops .list .block .item .sub {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.tops .list .block .item .sub .sub-item {
  line-height: 28px;
  display: flex;
}
.tops .list .block .item .sub .sub-item .label {
  font-size: 12px;
  color: var(--text-secondary);
  margin-right: 10px;
  width: 60px;
  text-align: right;
}
.tops .list .block .item .sub .sub-item .value {
  font-weight: 500;
}
.tops .list .block .item .go {
  position: absolute;
  width: 20px;
  height: 100%;
  top: 0;
  right: 0;
  background: url(./assets/icon/chevron-right.svg) center no-repeat;
  background-size: 18px;
}

.introduce {
  width: 100%;
  height: 700px;
  padding-top: 60px;
  background-image: url(./assets/img/home-intro-banner.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
}
.introduce .title {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.introduce .title .main {
  color: #0a0a0a;
  font-family: "Space Grotesk";
  font-size: 36px;
  font-weight: 700;
}
.introduce .title .sub {
  margin-top: 10px;
  color: #525252;
  font-family: Lato;
  font-size: 18px;
  font-weight: 400;
  line-height: 140%;
}
.introduce .content {
  flex: 1;
  padding: 20px 80px 20px 48%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.introduce .content .section .icon {
  width: 72px;
  height: 30px;
  border-radius: 8px;
  background-color: #ff4f20;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px;
}
.introduce .content .section.sec1 .icon {
  background-image: url(./assets/icon/yumaobi.svg);
}
.introduce .content .section.sec2 .icon {
  background-image: url(./assets/icon/diamond.svg);
}
.introduce .content .section.sec3 .icon {
  background-image: url(./assets/icon/xinhao.svg);
}
.introduce .content .section .label {
  color: #0a0a0a;
  font-family: "Space Grotesk";
  font-size: 18px;
  font-weight: 700;
}
.introduce .content .section .desc {
  color: #525252;
  font-family: "Lato";
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
}

.partners {
  padding-bottom: 80px;
}
.partners .title {
  padding: 40px 0 20px 0;
  color: #0a0a0a;
  font-family: "Space Grotesk";
  font-size: 36px;
  font-weight: 700;
  text-align: center;
}
.partners .list {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.partners .list .item {
  padding: 0 20px;
}
.partners .list .item .icon {
  min-width: 100px;
  height: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.partners .list .item .icon.hongshan {
  width: 130px;
  background-image: url(./assets/icon/pt-hongshan.svg);
}
.partners .list .item .icon.safepal {
  width: 130px;
  background-image: url(./assets/icon/pt-safepal.svg);
}
.partners .list .item .icon.trustwallet {
  width: 130px;
  background-image: url(./assets/icon/pt-trustwallet.svg);
}
.partners .list .item .icon.alumniventures {
  width: 200px;
  background-image: url(./assets/icon/pt-alumniventures.svg);
}
.partners .list .item .icon.gsr {
  background-image: url(./assets/icon/pt-gsr.svg);
}
.partners .list .item .icon.coinsummer {
  width: 190px;
  background-image: url(./assets/icon/pt-coinsummer.svg);
}
.partners .list .item .icon.onepiece {
  width: 140px;
  background-image: url(./assets/icon/pt-onepiece.png);
}

/* .instrument {
  width: 100%;
  height: 600px;
  display: flex;
}
.instrument .block {
  width: 50%;
}
.instrument .block.left {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 0 80px 0 120px;
}
.instrument .block.left .item .description1 {
  margin-top: 80px;
  font-size: 24px;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 10px;
}
.instrument .block.left .item .description2 {
  color: var(--text-secondary);
  line-height: 24px;
}
.instrument .block.right {
  padding: 0 120px 0 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.instrument .block.right .item {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.instrument .block.right .item .icon {
  width: 60px;
  height: 26px;
  border-radius: 13px;
  margin-bottom: 10px;
  background-size: 16px;
  background-position: center;
  background-repeat: no-repeat;
}
.instrument .block.right .item .icon.sec1 {
  background-image: url(./assets/icon/feather.svg);
  background-color: #6357ed;
}
.instrument .block.right .item .icon.sec2 {
  background-image: url(./assets/icon/gem.svg);
  background-color: #c86197;
}
.instrument .block.right .item .icon.sec3 {
  background-image: url(./assets/icon/radio.svg);
  background-color: #82d3a9;
}
.instrument .block.right .item .title {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}
.instrument .block.right .item .content {
  color: var(--text-secondary);
  line-height: 24px;
} */

.positive {
  color: #05a83e;
}

.negative {
  color: #ff4f20;
}
/* .sample .list .block .item .main {
    position: absolute;
    top: 20px;
    left: 20px;
    display: flex;
    align-items: center;
}
.sample .list .block .item .main .name {
    font-size: 20px;
    font-weight: 700;
    margin-right: 10px;
}
.sample .list .block .item .main .price {
    font-size: 16px;
    color: var(--primary);
}
.sample .list .block .item .tvl {
    position: absolute;
    right: 20px;
}
.sample .list .block .item .ytd {
    position: absolute;
    bottom: 20px;
}
.sample .list .block .item .h24vol {
    position: absolute;
    bottom: 20px;
    right: 20px;
} */
</style>