<template>
  <div id="container" class="container">
    <top-bar @changeMenu="changeMenu"></top-bar>

    <div class="box1">
      <div class="box11">
        <div class="box111">
          <div class="asset-name">{{ assetDetail.name }}</div>
          <div
            class="asset-price"
            :class="{
              positive: assetDetail.change24H > 0,
              negative: assetDetail.change24H < 0,
            }"
          >
            ${{ proxy.abs.Util.floor(assetDetail.price, 2) }}
          </div>
          <div
            class="asset-24hchange"
            :class="{
              positive: assetDetail.change24H > 0,
              negative: assetDetail.change24H < 0,
            }"
          >
            {{ proxy.abs.Util.addSign(assetDetail.change24H) }}%
          </div>
          <div class="asset-doc" @click="onClickDoc">
            Docs
            <img src="./assets/icon/docs.svg" alt="My SVG Image" />
          </div>
        </div>
        <div class="box112">
          {{ assetDetail.description }}
        </div>
        <div class="box113">
          <div class="asset_detail_item">
            <div class="label">24H Change</div>
            <div
              class="value"
              :class="{
                positive: assetDetail.change24H > 0,
                negative: assetDetail.change24H < 0,
              }"
            >
              {{ proxy.abs.Util.addSign(assetDetail.change24H, 2) }}%
            </div>
          </div>
          <div class="asset_detail_item">
            <div class="label">7D ROI</div>
            <div
              class="value"
              :class="{
                positive: assetDetail.roi7day > 0,
                negative: assetDetail.roi7day < 0,
              }"
            >
              {{ proxy.abs.Util.addSign(assetDetail.roi7day, 2) }}%
            </div>
          </div>
          <div class="asset_detail_item">
            <div class="label">1M ROI</div>
            <div
              class="value"
              :class="{
                positive: assetDetail.roi1Month > 0,
                negative: assetDetail.roi1Month < 0,
              }"
            >
              {{ proxy.abs.Util.addSign(assetDetail.roi1Month, 2) }}%
            </div>
          </div>
          <div class="asset_detail_item">
            <div class="label">3M ROI</div>
            <div
              class="value"
              :class="{
                positive: assetDetail.roi3Month > 0,
                negative: assetDetail.roi3Month < 0,
              }"
            >
              {{ proxy.abs.Util.addSign(assetDetail.roi3Month, 2) }}%
            </div>
          </div>
          <div class="asset_detail_item">
            <div class="label">YTD</div>
            <div
              class="value"
              :class="{
                positive: assetDetail.ytd > 0,
                negative: assetDetail.ytd < 0,
              }"
            >
              {{ proxy.abs.Util.addSign(assetDetail.ytd, 2) }}%
            </div>
          </div>
        </div>
        <div class="box114" id="box114">
          <div class="lw-chart" ref="chartContainer"></div>
        </div>
        <div class="box115">
          <div class="operate-item">ConstItuent Currencies</div>
        </div>
        <div class="box116">
          <div class="table-container">
            <table class="custom-table">
              <thead>
                <tr>
                  <th class="col-text">Coin</th>
                  <th class="col-number">Price</th>
                  <th class="col-number">MarketCap</th>
                  <th class="col-number">PositionRatio</th>
                  <th class="col-number">24H Change</th>
                  <th class="col-number">1M ROI</th>
                  <th class="col-number">1Y ROI</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in assetDetail.assetCoins"
                  :key="item.product"
                  class="content-row"
                >
                  <td class="col-text">{{ item.coin }}</td>
                  <td class="col-number">
                    ${{ proxy.abs.Util.formatNumber(item.price, 2) }}
                  </td>
                  <td class="col-number">
                    ${{ proxy.abs.Util.formatNumber(item.marketCap, 0) }}
                  </td>
                  <td class="col-number">
                    {{ proxy.abs.Util.formatNumber(item.positionRatio, 2) }}%
                  </td>
                  <td
                    class="col-number"
                    :class="{
                      positive: item.change24H > 0,
                      negative: item.change24H < 0,
                    }"
                  >
                    {{ proxy.abs.Util.addSign(item.change24H) }}%
                  </td>
                  <td
                    class="col-number"
                    :class="{
                      positive: item.roi1Month > 0,
                      negative: item.roi1Month < 0,
                    }"
                  >
                    {{ proxy.abs.Util.addSign(item.roi1Month) }}%
                  </td>
                  <td
                    class="col-number"
                    :class="{
                      positive: item.roi1Year > 0,
                      negative: item.roi1Year < 0,
                    }"
                  >
                    {{ proxy.abs.Util.addSign(item.roi1Year, 2) }}%
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="box12">
        <div class="buy-asset">
          <div class="title">Buy SoSo Crypto Index</div>
          <div class="buy-button">
            <button
              class="button primary-button"
              :disabled="assetDetail.tradable == false"
              style="width: 100%"
              @click="onClickBuy"
              :class="{ disabled: assetDetail.tradable == false }"
            >
              Buy now
            </button>
          </div>
        </div>
        <div class="asset-info">
          <div class="info">
            <div class="info-title">Market Cap</div>
            <div class="info-value" v-if="assetDetail.tradable">
              ${{ proxy.abs.Util.formatNumber(assetDetail.marketCap, 2) }}
            </div>
            <div class="info-value" v-else>-</div>
          </div>
          <div class="info">
            <div class="info-title">24H Vol</div>
            <div class="info-value" v-if="assetDetail.tradable">
              ${{ proxy.abs.Util.formatNumber(assetDetail.vol24H, 2) }}
            </div>
            <div class="info-value" v-else>-</div>
          </div>
          <div class="info">
            <div class="info-title">Premium/Discount</div>
            <div class="info-value" v-if="assetDetail.tradable">
              {{ proxy.abs.Util.floor(assetDetail.premium, 2) }}%
            </div>
            <div class="info-value" v-else>-</div>
          </div>
          <div class="info">
            <div class="info-title">Net Assets</div>
            <div class="info-value" v-if="assetDetail.tradable">
              ${{ proxy.abs.Util.formatNumber(assetDetail.netAssets, 2) }}
            </div>
            <div class="info-value" v-else>-</div>
          </div>
          <div class="info">
            <div class="info-title">Current Supply</div>
            <div class="info-value" v-if="assetDetail.tradable">
              {{ proxy.abs.Util.floor(assetDetail.currentSupply, 2) }}
            </div>
            <div class="info-value" v-else>-</div>
          </div>
          <div
            class="info"
            style="flex-direction: column; align-items: flex-start"
          >
            <div class="info-title">Token Address</div>
            <div
              style="margin-top: 10px; overflow-wrap: break-word; width: 300px"
            >
              <a :href="assetDetail.addressLink" target="_blank">{{
                assetDetail.address
              }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <bottom-bar></bottom-bar>
  </div>
</template>

<script setup>
import { getCurrentInstance, onBeforeUnmount, onMounted, ref } from "vue";
import { createChart } from "lightweight-charts";

import { useRoute, useRouter } from "vue-router";
const { proxy } = getCurrentInstance();
const route = useRoute();
const router = useRouter();

const changeMenu = (menuItem) => {
  if (route.path === proxy.abs.routes[menuItem]) {
    return;
  }
  router.push(proxy.abs.routes[menuItem]);
};

const chartContainer = ref();
const assetDetail = ref({
  id: 1,
  address: "",
  marketCap: 0,
  vol24H: 0,
  premium: 0,
  name: "",
  change24H: 0,
  roi1Month: 0,
  roi3Month: 0,
  roi7day: 0,
  price: 0,
  ytd: 0,
  addressLink: "",
  description: "",
  assetCoins: [],
  tradable: false,
});
const getAssetDetail = () => {
  proxy.api.getAssetDetail(
    proxy,
    {
      indexId: route.params.assetId,
    },
    (data) => {
      data.data.asset_detail.name = data.data.asset_detail.name.replace('_','');
      assetDetail.value = data.data.asset_detail;
      // for (let i = 0; i < 11; i++) {
      //   assetDetail.value.assetCoins.push(assetDetail.value.assetCoins[0]);
      // }
    },
    (message) => {
      console.log(message);
    }
  );
};
let kline = {
  chart: null,
  chartOptions: null,
  initViewCount: 150,
  onceLoadCount: 700,
  loadNewDataRate: 0.6,
  loadingKline: false,
  candlestickSeries: null,
  loadTimes: 0,
  init: true,
  data: [],
  nowTimeStmp: 0,
  leftTimeStmp: 0,
  enbaleLoad: true,
};
onMounted(() => {
  getAssetDetail();
  initChart();
});

const initChart = () => {
  let now = new Date();
  kline.nowTimeStmp = Math.floor(now.getTime() / 1000);
  kline.chartOptions = {
    layout: {
      textColor: "black",
      background: { type: "solid", color: "#f5f5f5" },
    },
    localization: {
      locale: "en",
    },
    grid: {
      vertLines: false, // 隐藏垂直网格线
      horzLines: false, // 隐藏水平网格线
    },
    scaleMargins: {
      top: 0.4, // 上方留白 20%
      bottom: 0.2, // 下方留白 20%
    },
    timeScale: {
      timeVisible: true, // 显示时间
    },
    height: 300,
  };
  kline.chart = createChart(chartContainer.value, kline.chartOptions);
  kline.candlestickSeries = kline.chart.addCandlestickSeries({
    upColor: "#26a69a",
    downColor: "#ef5350",
    borderVisible: false,
    wickUpColor: "#26a69a",
    wickDownColor: "#ef5350",
  });

  loadKlineData(kline.nowTimeStmp, kline.onceLoadCount);
  kline.chart.timeScale().subscribeVisibleTimeRangeChange(handleChartScroll);
};

const loadKlineData = (stmp) => {
  if (!kline.enbaleLoad) {
    return;
  }
  if (kline.loadingKline) {
    return;
  }
  kline.loadingKline = true;
  proxy.api.loadKlineData(
    proxy,
    {
      indexId: route.params.assetId,
      endStmp: stmp,
      limit: kline.onceLoadCount,
      interval: "1h",
    },
    (data) => {
      console.log("left", data.data.kline[0].time);
      console.log("righ", data.data.kline[data.data.kline.length - 1].time);
      console.log("len: ", data.data.kline.length);
      kline.data.unshift(...data.data.kline);
      kline.candlestickSeries.setData(kline.data);
      if (kline.init) {
        const visibleRange = {
          from: kline.onceLoadCount - kline.initViewCount + 1,
          to: kline.onceLoadCount,
        };
        kline.chart.timeScale().setVisibleLogicalRange(visibleRange);
      }
      kline.leftTimeStmp = data.data.kline[0].time;
      kline.loadingKline = false;
      kline.loadTimes += 1;
    },
    (message) => {
      kline.loadingKline = false;

      console.log(message);
    }
  );
};
let flag = true;
const handleChartScroll = (newVisibleRange) => {
  if (flag) {
    flag = false;
    return;
  }
  if (kline.init) {
    kline.init = false;
    return;
  }
  if (!newVisibleRange) {
    return;
  }
  // console.log("from: ", newVisibleRange.from);
  // console.log("to: ", newVisibleRange.to);
  let leftDataNum = Number(
    (newVisibleRange.from - kline.leftTimeStmp) / 3600 + 1
  );
  // console.log("leftDataNum: ", leftDataNum);
  let rate = Number(leftDataNum / kline.onceLoadCount);
  // console.log("rate: ", rate);
  if (rate > kline.loadNewDataRate) {
    return;
  }
  loadKlineData(kline.leftTimeStmp);
};

//点购买按钮
const onClickBuy = () => {
  console.log(assetDetail.value.address);
  window.open(
    "https://app.uniswap.org/swap?inputCurrency=0xdAC17F958D2ee523a2206206994597C13D831ec7&outputCurrency=" +
      assetDetail.value.address,
    "_blank"
  );
};

onBeforeUnmount(() => {
  if (kline.chart) {
    kline.chart.remove;
  }
});

const onClickDoc = () => {
  const url = router.resolve({
    name: "AssetDocs",
    params: {
      assetId: assetDetail.value.id,
    },
  }).href;
  window.open(url, "_blank");
};
</script>

<style scoped>
.container .box1 {
  display: flex;
  flex-direction: row;
  height: 875px;
  margin: 20px;
  gap: 10px;
}
.container .box1 .box11 {
  height: 100%;
  flex: 7;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 32px 20px 32px 20px;
}
.container .box1 .box12 {
  padding: 32px 20px 32px 20px;
  height: 100%;
  flex: 2;
  gap: 20px;
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 10px; /* 设置所有角的圆角半径为 10px */
}
.container .box1 .box11 .box111 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
}

.container .box1 .box11 .box111 .asset-name {
  color: rgb(60, 60, 60);
  font-size: 22px;
  font-weight: 600;
}
.container .box1 .box11 .box111 .asset-price {
  font-size: 21px;
  font-weight: 400;
}

.container .box1 .box11 .box111 .asset-24hchange {
  font-size: 21px;
  font-weight: 400;
}
.container .box1 .box11 .box111 .asset-doc {
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-left: auto;
  cursor: pointer;
}
.test {
  color: rgb(118, 118, 148);
}
.container .box1 .box11 .box112 {
  display: flex;
  flex-direction: row;
  font-size: 13px;
  color: #9c9c9c;
}

.container .box1 .box11 .box113 {
  border: 1px;
  display: flex;
  flex-direction: row;
  border: 1px solid #ccc;
  border-radius: 10px; /* 设置所有角的圆角半径为 10px */
  padding: 10px 20px;
  justify-content: space-between;
  background-color: #f5f5f5;
}
.container .box1 .box11 .box113 .asset_detail_item {
  display: flex;
  flex-direction: column;
}
.container .box1 .box11 .box113 .asset_detail_item .label {
  color: rgb(53, 53, 53);
  font-size: 15px;
}
.container .box1 .box11 .box113 .asset_detail_item .value {
  margin-top: 7px;
}

.container .box1 .box11 .box114 {
  border: 1px solid #ccc;
  border-radius: 10px; /* 设置所有角的圆角半径为 10px */
  padding: 1px;
  background-color: "#f5f5f5";
}
.container .box1 .box11 .box115 {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.container .box1 .box11 .box116 {
  height: 200px;
}
.container .box1 .box11 .box115 .operate-item {
}
.container .box1 .box12 .buy-asset {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.container .box1 .box12 .buy-asset .title {
  font-size: 17px;
  font-weight: 600;
  align-self: center;
}
.container .box1 .box12 .buy-asset .select-asset {
  align-self: flex-start;
}
.container .box1 .box12 .buy-asset .buy-button {
}

.container .box1 .box12 .asset-info {
  display: flex;
  flex-direction: column;
}
.container .box1 .box12 .asset-info .info {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--sep-color);
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.container .box1 .box12 .asset-info .info {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--sep-color);
  justify-content: space-between;
  align-items: center;
  padding: 7px;
  margin-bottom: 20px;
}
.container .box1 .box12 .asset-info .info .info-title {
  font-size: 17px;
  font-weight: 500;
  color: rgb(101, 101, 101);
}
.container .box1 .box12 .asset-info .info .info-value {
  color: rgb(49, 48, 48);

  font-weight: 500;
  font-size: 17px;
  text-align: right;
  overflow-wrap: break-word;
}

.table-container {
  height: 300px;
  overflow-y: auto; /* 使内容溢出时显示垂直滚动条 */
  border: 1px solid #ccc;
  border-radius: 10px;
  clip-path: inset(0 round 10px);
}
.table-container .custom-table {
  width: 100%;
  border-collapse: collapse;
  background-color: transparent;
}
.table-container .custom-table thead th {
  background-color: #ececec;
  position: sticky;
  top: 0;
  z-index: 1;
}
.table-container .custom-table .content-row {
  cursor: pointer;
}
.table-container .custom-table th,
.table-container .custom-table td {
  padding: 10px;
}
.table-container .custom-table tr {
  border-bottom: none;
}
.table-container .custom-table thead {
  font-size: 16px;
  color: rgb(74, 74, 74);
  font-weight: 400;
  height: 50px;
}

.positive {
  color: rgb(0 168 62);
}

.negative {
  color: rgb(255 58 51);
}

.col-number {
  text-align: right;
}
.col-text {
  text-align: left;
}
</style>
